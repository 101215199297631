import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import TeamBread from "../elements/common/TeamBread";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";

class Team extends Component {
  constructor() {
    super();
    this.state = {
      team: [],
    };
  }

  componentDidMount() {
    const lang_url = this.props.match.params.lang;
    //Бүтээл дэлгэрэнгүй хэсгийн API
    fetch(
      "http://contentlab.mn/teq111/json/team.php?lang=" + lang_url
    )
      .then((response) => response.json())
      .then((data) => this.setState({ team: data }));
  }

  render() {
    const lang_url = this.props.match.params.lang;
    var lang_title = "Team";
    var our_team = "Our team"
    if (lang_url === "mn") {
      lang_title = "Баг";
      our_team = "Манай баг"
    } else {
      lang_title = "Team";
      our_team = "Our team"
    }
    const team = this.state.team;
    return (
      <React.Fragment>
        <PageHelmet pageTitle={lang_title} />
        <Header
          lang_url={lang_url}
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* End Header Area  */}
        {/* Start Breadcrump Area */}
        <TeamBread title={lang_title} />
        {/* End Breadcrump Area */}
        {/* Start Page Wrapper  */}
        <main className="page-wrapper">
          {/* Start Team Area  */}
          <div className="rn-team-wrapper ptb--120 bg_color--1">
            <div className="rn-team-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center mb--30">
                      <h2>{our_team}</h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {team.map((team, index) => (
                    <div className="col-lg-4" key={index}>
                      <div className="team">
                        <div className="thumbnail">
                          <img src={`${team.picture}`} alt="Blog Images" />
                        </div>
                        <div className="content">
                          <h4 className="title">{team.name}</h4>
                          <p className="designation">{team.title}</p>
                        </div>
                        {/* <ul className="social-icon" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* End Team Area  */}
        </main>
        {/* End Page Wrapper  */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        {/* Start Footer Area  */}
        <CallAction lang_url={lang_url}/>
        <FooterTwo />
        {/* End Footer Area  */}
      </React.Fragment>
    );
  }
}

export default Team;
