import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ServiceBread from "../elements/common/ServiceBread";
import {
  // FiCast,
  // FiLayers,
  // FiUsers,
  // FiMonitor,
  FiChevronUp,
} from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import parse from "html-react-parser";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

// Add all icons to the library so you can use it in your page
library.add(fas, far, fab);
class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: [],
    };
  }
  componentDidMount() {
    const lang_url = this.props.match.params.lang;
    fetch(
      "http://contentlab.mn/teq111/json/service.php?lang=" + lang_url
    )
      .then((response) => response.json())
      .then((data) => this.setState({ service: data }));
  }
  render() {
    const lang_url = this.props.match.params.lang;
    var lang_title = "Service";
    if (lang_url === "mn") {
      lang_title = "Үйлчилгээ";
    } else {
      lang_title = "Service";
    }
    const service = this.state.service;
    return (
      <React.Fragment>
        <PageHelmet pageTitle={lang_title} />
        <Header
          lang_url={lang_url}
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <ServiceBread title={lang_title} />
        {/* End Breadcrump Area */}

        {/* Start Service Area */}
        <div className="service-area ptb--120 bg_color--5">
          <div className="container">
            {/* <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Digital Marketing</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, <br /> but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div> */}
            <div className="row service-one-wrapper">
              {service.map((service, index) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                  key={index}
                >
                  <a href={`service-details/${service.id}/${lang_url}`}>
                    <div className="service service__style--2">
                      <div className="content">
                        <img
                          src={`${service.icon}`}
                          style={{
                            display: "block",
                            paddingBottom: "30px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          width={70}
                          alt="service"
                        />
                        <h3 className="title" style={{ textAlign: "center" }}>
                          <strong>{service.title}</strong>
                        </h3>
                        <p style={{ textAlign: "justify" }}>
                          {service.short_desc ? parse(service.short_desc) : service.short_desc}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <CallAction lang_url={lang_url} />
        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default Service;
