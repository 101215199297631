import React, { Component, Fragment } from "react";
// import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
// import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import {
  slickDotTeam,
  portfolioSlick2,
  bannerSlick,
} from "../page-demo/script";
// import ServiceList from "../elements/service/ServiceList";
import BrandTwo from "../elements/BrandTwo";
// import PortfolioList from "../elements/portfolio/PortfolioList";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
// import Team from "../elements/Team";
// import Accordion01 from "../elements/Accordion";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
// import News from "../elements/blog/BlogContent";
import parse from "html-react-parser";
// Add all icons to the library so you can use it in your page
// library.add(fas, far, fab);
// import { queryCache } from "react-query";

class Business extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      newsShort: [],
      slideList: [],
      productShort: [],
      serviceShort: [],
      about: [],
      team: [],
      category: [],
      isLoading: true,
      error: null,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }
  async componentDidMount() {
    //Slide хэсгийн API
    const res = await fetch("http://contentlab.mn/teq111/json/slide.php");
    const slideList = await res.json();
    this.setState({
      isLoading: false,
      slideList: slideList,
    });
    // queryCache.setQueryData("slide", slideList); // cache the data

    const lang_url = this.props.lang_url;
    //Мэдээ хэсгийн API
    fetch("http://contentlab.mn/teq111/json/news.php?lang=" + lang_url)
      .then((response) => response.json())
      .then((data) => this.setState({ newsShort: data }));
    //Бүтээл хэсгийн API
    fetch("http://contentlab.mn/teq111/json/product_home.php?lang=" + lang_url)
      .then((response) => response.json())
      .then((data) => this.setState({ productShort: data }));
    //Үйлчилгээ хэсгийн API
    fetch("http://contentlab.mn/teq111/json/service.php?lang=" + lang_url)
      .then((response) => response.json())
      .then((data) => this.setState({ serviceShort: data }));
    //About хэсгийн API
    fetch("http://contentlab.mn/teq111/json/about.php?lang=" + lang_url)
      .then((response) => response.json())
      .then((data) => this.setState({ about: data }));
    //Баг хэсгийн API
    fetch("http://contentlab.mn/teq111/json/team.php?lang=" + lang_url)
      .then((response) => response.json())
      .then((data) => this.setState({ team: data }));
    fetch("http://contentlab.mn/teq111/json/category.php?lang=" + lang_url)
      .then((response) => response.json())
      .then((data) => this.setState({ category: data }));
  }

  render() {
    //language
    const lang = this.props.blah;
    const lang_url = this.props.lang_url;
    //Слайяд header
    const { isLoading, error, slideList } = this.state;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    }
    //Мэдээ slice хийх
    const news = this.state.newsShort;
    const newsSliced = news.slice(0, 6);

    //Бүтээл slice хийх
    const product = this.state.productShort;
    const productSliced = product.slice(0, 12);
    //Үйлчилгээ parsing хийх
    const serviceNonSliced = this.state.serviceShort;
    const service = serviceNonSliced.slice(0, 6);
    const team = this.state.team;

    const category = this.state.category;
    return (
      <Fragment>
        <Helmet pageTitle="Нүүр" />
        <Header lang_url={lang_url} />
        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider {...bannerSlick} className="rn-slick-dot dot-light">
              {slideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image bannerSlider`}
                  key={index}
                  style={{ backgroundImage: `url(${value.picture})` }}
                  data-black-overlay="3"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner`}>
                          {/* {value.category ? <span>{value.category}</span> : ""} */}
                          {value.title ? (
                            <h1 className="title">{value.title}</h1>
                          ) : (
                            ""
                          )}
                          {/* {value.desc ? (
                            <p className="description">{value.desc}</p>
                          ) : (
                            ""
                          )} */}
                          {/* {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-solid"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Brand Area */}

        <div className="rn-brand-area bg_color--5 ptb--80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                  <h3 className="title">{lang("home_logo")}</h3>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Service Area  */}
        <div className="service-area creative-service-wrapper ptb--80 bg_color--1">
          <div className="container containerService">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                  <h3 className="title">{lang("home_service")}</h3>
                  {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p> */}
                </div>
              </div>
            </div>
            <div className="row creative-service desktopService">
              {/* <div className="col-lg-12"> */}
              {service.map((service, index) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                  key={index}
                >
                  <a href={`service/service-details/${service.id}/${lang_url}`}>
                    <div className="service service__style--2">
                      <div className="content">
                        <img
                          src={`${service.icon}`}
                          style={{
                            display: "block",
                            paddingBottom: "30px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          width={70}
                          alt="service"
                        />
                        <h3 className="title" style={{ textAlign: "center" }}>
                          <strong>{service.title}</strong>
                        </h3>
                        <p style={{ textAlign: "justify" }}>
                          {service.short_desc
                            ? parse(service.short_desc)
                            : service.short_desc}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
              {/* </div> */}
            </div>
            <div className="wrapper portfolio-sacousel-inner mb--55 phoneService">
              <div className="portfolio-slick-activation mt--70 mt_sm--40">
                <Slider {...portfolioSlick2}>
                  {service.map((service, index) => (
                    <a
                      href={`service/service-details/${service.id}/${lang_url}`}
                    >
                      <div
                        className="service service__style--2"
                        style={{ height: "500px" }}
                      >
                        <div className="content">
                          <img
                            src={`${service.icon}`}
                            style={{
                              display: "block",
                              paddingBottom: "30px",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            width={70}
                            alt="service"
                          />
                          <h3 className="title" style={{ textAlign: "center" }}>
                            <strong>{service.title}</strong>
                          </h3>
                          <p style={{ textAlign: "justify" }}>
                            {service.short_desc
                              ? parse(service.short_desc)
                              : service.short_desc}
                          </p>
                        </div>
                      </div>
                    </a>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="view-more-btn mt--60 text-center">
                  <a
                    className="rn-button-style--2 btn-solid"
                    href={`/service/${lang_url}`}
                  >
                    <span>{lang("other_service")}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start About Area  */}
        {/* <div className="rn-about-area ptb--80 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src={`${about.picture}`}
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{about.title}</h2>
                    <p>{parseDesc}</p>
                  </div>
                  <div className="accordion-wrapper mt--30">
                    <Accordion01 />
                  </div>
                  <div className="about-button mt--50">
                    <a className="rn-button-style--2 btn-solid" href="/about">
                      Цааш унших
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End About Area  */}

        {/* Start About Area  */}
        {/* <div className="rn-about-area ptb--80 bg_color--1">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">About</h2>
                    <p className="description">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour, or randomised words which
                      dont look even slightly believable.
                    </p>
                  </div>
                  <div className="mt--30">
                    <h4>Lorem ipsum dolor sit.</h4>
                    <ul className="list-style--1">
                      {namesItemOne.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="mt--30">
                    <h4>Lorem ipsum dolor sit.</h4>
                    <ul className="list-style--1">
                      {namesItemTwo.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail position-relative">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-3.png"
                    alt="About Images"
                  />
                  <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen}
                    videoId="ZOoVOfieAF8"
                    onClose={() => this.setState({ isOpen: false })}
                  />
                  <button
                    className="video-popup position-top-center theme-color"
                    onClick={this.openModal}
                  >
                    <span className="play-icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End About Area  */}

        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--80 bg_color--5">
          <div className="portfolio-sacousel-inner">
            {/* <div className="container"> */}
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                  <h3 className="title">{lang("home_job")}</h3>
                  {/* <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration.
                    </p> */}
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "10px" }}>
              <div className="col-lg-12">
                <div className="view-more-btn text-center">
                  <a
                    className="home_category_button btn-solid"
                    href={`/job/${lang_url}`}
                  >
                    <span>{lang("all")}</span>
                  </a>
                  {category.map((value, i) => (
                    <a
                      className="home_category_button btn-solid"
                      href={`/job/${value.id}/${lang_url}`}
                    >
                      <span>{value.title}</span>
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="row desktopProduct">
              {productSliced.map((product, index) => (
                <div className="col-lg-3 col-md-3 col-sm-6 col-12" key={index}>
                  <div className="portfolio text-left mt--40">
                    <div className="thumbnail-inner">
                      <div
                        className={`thumbnail`}
                        style={{ backgroundImage: `url(${product.picture})` }}
                      ></div>
                      <div
                        className="bg-blr-image"
                        style={{ backgroundImage: `url(${product.picture})` }}
                      ></div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        {/* <p>{product.category}</p> */}
                        <h4>
                          <Link
                            to={`/product-details/${product.id}/${product.cat_id}/${lang_url}`}
                          >
                            {product.title}
                          </Link>
                        </h4>
                        <div className="portfolio-button">
                          <Link
                            className="rn-btn"
                            to={`/product-details/${product.id}/${product.cat_id}/${lang_url}`}
                          >
                            {lang("view")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="wrapper portfolio-sacousel-inner mb--55 phoneProduct">
              <div className="portfolio-slick-activation mt--70 mt_sm--40">
                <Slider {...portfolioSlick2}>
                  {productSliced.map((product, index) => (
                    <div className="portfolio" key={index}>
                      <div className="thumbnail-inner">
                        <div
                          className={`thumbnail`}
                          style={{ backgroundImage: `url(${product.picture})` }}
                        ></div>
                        <div
                          className="bg-blr-image"
                          style={{ backgroundImage: `url(${product.picture})` }}
                        ></div>
                      </div>
                      <div className="content">
                        <div className="inner">
                          <p>{product.category}</p>
                          <h4>
                            <a href={`blog-details/${news.id}/${lang_url}`}>
                              {product.title}
                            </a>
                          </h4>
                          <div className="portfolio-button">
                            <a
                              className="rn-btn"
                              href={`blog-details/${news.id}/${lang_url}`}
                            >
                              {lang("view")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="view-more-btn mt--60 text-center">
                  <a
                    className="rn-button-style--2 btn-solid"
                    href={`/job/${lang_url}`}
                  >
                    <span>{lang("other_job")}</span>
                  </a>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start Team Area  */}
        {/* <div className="rn-team-area ptb--80 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                  <h2 className="title">Баг</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {team.map((team, index) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                  <div className="team">
                    <div className="thumbnail">
                      <img src={`${team.picture}`} alt="Blog Images" />
                    </div>
                    <div className="content">
                      <h4 className="title">{team.name}</h4>
                      <p className="designation">{team.title}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}

        <div className="rn-team-wrapper ptb--120 bg_color--1 desktopTeam">
          <div className="rn-team-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center mb--30">
                    <h2>{lang("team")}</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                {team.map((team, index) => (
                  <div className="col-lg-4" key={index}>
                    <div className="team">
                      <div className="thumbnail">
                        <img src={`${team.picture}`} alt="Blog Images" />
                      </div>
                      <div className="content">
                        <h4 className="title">{team.name}</h4>
                        <p className="designation">{team.title}</p>
                      </div>
                      {/* <ul className="social-icon" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="rn-team-wrapper ptb--80 bg_color--1 phoneTeam">
          <div className="rn-team-area">
            <div className="container ml-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h3 className="title">{lang("team")}</h3>
                    {/* <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration.
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="portfolio-area pt--90 pb--140 bg_color--1">
                <div className="rn-slick-dot">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="slick-space-gutter--15 slickdot--20">
                          <Slider {...slickDotTeam}>
                            {team.map((value, index) => (
                              <div className="team" key={index}>
                                <div className="thumbnail">
                                  <img src={`${value.picture}`} alt="member" />
                                </div>
                                <div className="content">
                                  <h4 className="title">{value.name}</h4>
                                  <p className="designation">{value.title}</p>
                                </div>
                              </div>
                            ))}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Team Area  */}

        {/* Start Blog Area */}
        <div className="rn-blog-area pt--120 pb--80 bg_color--5">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center">
                  <h3 className="title">{lang("home_news")}</h3>
                  {/* <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="row mt--60 desktopNews">
              {newsSliced.map((news, index) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <Link to={`blog-details/${news.id}/${lang_url}`}>
                        <img
                          className="w-100"
                          src={`${news.picture}`}
                          alt="Blog Images"
                        />
                      </Link>
                    </div>
                    <div className="content">
                      {/* <p className="blogtype">{news.title}</p> */}
                      <h4 className="title">
                        <Link to={`blog-details/${news.id}/${lang_url}`}>
                          {news.title}
                        </Link>
                      </h4>
                      <div className="blog-btn">
                        <Link
                          to={`blog-details/${news.id}/${lang_url}`}
                          className="rn-btn text-white"
                        >
                          {lang("view")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="wrapper portfolio-sacousel-inner mb--55 phoneNews">
              <div className="portfolio-slick-activation mt--70 mt_sm--40">
                <Slider {...portfolioSlick2}>
                  {newsSliced.map((news, index) => (
                    <div className="blog blog-style--1">
                      <div className="thumbnail">
                        <Link to={`/blog-details/${news.id}/${lang_url}`}>
                          <img
                            className="w-100"
                            src={`${news.picture}`}
                            alt="Blog Images"
                          />
                        </Link>
                      </div>
                      <div className="content">
                        {/* <p className="blogtype">{news.title}</p> */}
                        <p
                          style={{
                            color: "white",
                            fontSize: "10px !important;",
                            lineHeight: "1",
                          }}
                        >
                          {" "}
                          <a
                            style={{ color: "white", fontSize: "10px" }}
                            href={`/blog-details/${news.id}/${lang_url}`}
                          >
                            {news.title}
                          </a>
                        </p>
                        <div className="blog-btn">
                          <a
                            className="rn-btn text-white"
                            style={{ color: "white", fontSize: "10px" }}
                            href={`/blog-details/${news.id}/${lang_url}`}
                          >
                            {lang("view")}
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Area */}

        {/* Start call To Action  */}
        <CallAction lang_url={lang_url} />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default Business;
