import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiMail, FiMapPin } from "react-icons/fi";
// import GoogleMapReact from "google-map-react";
// import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 47.92979191611653,
      lng: 106.91625836029235,
    },
    zoom: 11,
  };
  constructor(props) {
    super(props);
    this.state = {
      contact: [],
    };
  }
  componentDidMount() {
    const lang_url = this.props.match.params.lang;
    console.log(lang_url);
    fetch(
      "http://contentlab.mn/teq111/json/contact.php?lang=" + lang_url
    )
      .then((response) => response.json())
      .then((data) => this.setState({ contact: data }));
  }
  render() {
    const lang_url = this.props.match.params.lang;
    var lang_title = "Contact us";
    var lang_contact = "Бидэнтэй холбоо барих";

    if (lang_url === "mn") {
      lang_title = "Холбоо барих";
      lang_contact = "Бидэнтэй холбоо барих";
    } else {
      lang_title = "Contact us";
      lang_contact = "Contact us";
    }
    const contact = this.state.contact;
    return (
      <React.Fragment>
        <PageHelmet pageTitle={lang_title} />

        <Header
          lang_url={lang_url}
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--34"
          data-black-overlay="6"
          style={{ backgroundImage: `url(${contact.contact_banner})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">{lang_contact}</h2>
                  {/* <p>{contact.vision}</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <div className="inner">
                    <h4 className="title">Утсаар холбогдох</h4>
                    <p>
                      <a href={`tel: +976 ${contact.phone}`}>
                        +976 {contact.phone}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Имэйл хаягаар холбогдох</h4>
                    <p>
                      <a href={`mailto: ${contact.email}`}>{contact.email}</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Хаяг</h4>
                    <p> {contact.address} </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Contact Page Area  */}
        {/* <div className="rn-contact-page ptb--120 bg_color--1">
          <ContactTwo />
        </div> */}
        {/* End Contact Page Area  */}

        {/* Start Contact Map  */}
        {/* <div className="rn-contact-map-area position-relative">
          <div style={{ height: "650px", width: "100%" }}>
            <GoogleMapReact
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
            >
              <AnyReactComponent
                lat={47.92979191611653}
                lng={106.91625836029235}
                text="My Marker"
              />
            </GoogleMapReact>
          </div>
        </div> */}
        {/* End Contact Map  */}

        {/* Start Brand Area */}
        <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <CallAction lang_url={lang_url}/>
        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default Contact;
