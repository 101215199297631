import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import parse from "html-react-parser";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Link } from "react-router-dom";
// import ModalVideo from "react-modal-video";
// import $ from "jquery";

class ProductDetails extends Component {
  constructor() {
    super();
    this.state = {
      tab1: 0,
      isOpen: false,
      productDetail: [],
      product: [],
      videoUrl: [],
      isOpenVideo: false,
    };
    this.openModal = this.openModal.bind(this);
    this.openModalVideo = this.openModalVideo.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  openModalVideo() {
    this.setState({ isOpenVideo: true });
    // $(".showIframe").show();
  }
  componentDidMount() {
    const product_id = this.props.match.params.id;
    const cat_id = this.props.match.params.cat_id;
    // const lang_url = this.props.match.params.lang;
    //Бүтээл дэлгэрэнгүй хэсгийн API
    fetch(
      "http://contentlab.mn/teq111/json/product_detail.php?product_id=" +
        product_id
    )
      .then((response) => response.json())
      .then((data) => this.setState({ productDetail: data }));
    fetch(
      "http://contentlab.mn/teq111/json/product_related.php?product_id=" +
        product_id +
        "&cat_id=" +
        cat_id
    )
      .then((response) => response.json())
      .then((data) => this.setState({ product: data }));
    fetch(
      "http://contentlab.mn/teq111/json/product_video.php?product_id=" +
        product_id
    )
      .then((response) => response.json())
      .then((data) => this.setState({ videoUrl: data }));
    //jquery
  }

  render() {
    const { tab1, isOpen } = this.state;
    const lang_url = this.props.match.params.lang;
    const productDetail = this.state.productDetail;
    const description = productDetail.description;
    const stringDesc = JSON.stringify(description);
    const subDesc = stringDesc
      ? stringDesc.substring(1, stringDesc.length - 1)
      : " ";
    const parseDesc = parse(subDesc);
    const product = this.state.product;
    const productSliced = product.slice(0, 3);
    const imageUrl = productDetail.imageUrl;
    const videoUrl = this.state.videoUrl;
    let pictureArray = [];
    for (let key in imageUrl) {
      pictureArray.push(imageUrl[key].picture);
    }

    var view = "View more";
    var related_work = "Related works";
    var our_more_project = "Our more project";
    var other_project = "Other products";
    if (lang_url === "mn") {
      related_work = "ТӨСТЭЙ БҮТЭЭЛҮҮД";
      our_more_project = "МАНАЙ БУСАД БҮТЭЭЛҮҮД";
      view = "Дэлгэрэнгүй";
      other_project = "Бусад бүтээлүүд";
    } else {
      related_work = "RELATED WORKS";
      our_more_project = "OUR MORE PROJECTS";
      view = "View more";
      other_project = "Other products";
    }
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Бүтээл" />

        <Header
          lang_url={lang_url}
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <div
          className="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--34 breadCover"
          style={{ backgroundImage: `url(${productDetail.cover})` }}
          data-black-overlay="3"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner pt--100">
                  <h2 className="title">{productDetail.title}</h2>
                  <ul className="page-list">
                    <li className="breadcrumb-item">
                      <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {productDetail.title}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-portfolio-details ptb--40 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="inner">
                    <h2>{productDetail.title}</h2>
                    {parseDesc !== "" ? parseDesc : null}
                    <div className="portfolio-view-list d-flex flex-wrap">
                      {productDetail.client !== "" ? (
                        <div className="port-view">
                          <span>Client</span>
                          <strong>{productDetail.client}</strong>
                        </div>
                      ) : null}

                      {productDetail.client !== "" ? (
                        <div className="port-view">
                          <span>Year</span>
                          <span>
                            <strong>{productDetail.year}</strong>
                          </span>
                        </div>
                      ) : null}

                      {productDetail.client !== "" ? (
                        <div className="port-view">
                          <span>Services</span>
                          <span>
                            <strong>{productDetail.services}</strong>
                          </span>
                        </div>
                      ) : null}

                      {productDetail.client !== "" ? (
                        <div className="port-view">
                          <span>Website</span>
                          <span>
                            <strong>{productDetail.link}</strong>
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {videoUrl.length !== 0 ? (
          <div className="rn-section ptb--40 bg_color--1">
            <div className="container">
              <div className="row service-details-content align-items-center">
              {videoUrl.length === 1 ? (
                  <div className="col-lg-4"></div>
                ) : null}
                {videoUrl.length === 2 ? (
                  <div className="col-lg-2"></div>
                ) : null}
                {videoUrl.map((video, index) => (
                  <div className="col-lg-4" key={index}>
                    {/* <div className="thumb position-relative">
                      <img
                        style={{
                          height: "300px",
                          objectFit: "cover",
                        }}
                        className="w-100"
                        src={video.video_picture}
                        alt="Service Images"
                      />
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpenVideo}
                        videoId={video.video_url}
                        onClose={() => this.setState({ isOpenVideo: false })}
                      />
                      <button
                        className="video-popup position-top-center theme-color md-size videoClick"
                        onClick={this.openModalVideo}
                      >
                        <span className="play-icon"></span>
                      </button>
                    </div> */}
                    <div
                      id={video.video_id}
                      className="thumb position-relative"
                    >
                      <iframe
                        title={index}
                        className="w-100"
                        style={{
                          height: "300px",
                          objectFit: "cover",
                        }}
                        src={`https://www.youtube.com/embed/${video.video_url}`}
                      />
                    </div>
                  </div>
                ))}
                {videoUrl.length === 2 ? (
                  <div className="col-lg-2"></div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {pictureArray.length !== 0 ? (
          <div className="rn-portfolio-area ptb--40 bg_color--1">
            <div className="container">
              <div className="row">
                {pictureArray.map((value, index) => (
                  <div className="col-lg-3" key={index}>
                    {isOpen && (
                      <Lightbox
                        mainSrc={pictureArray[tab1]}
                        nextSrc={pictureArray[(tab1 + 1) % pictureArray.length]}
                        prevSrc={
                          pictureArray[
                            (tab1 + pictureArray.length - 1) %
                              pictureArray.length
                          ]
                        }
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                          this.setState({
                            tab1:
                              (tab1 + pictureArray.length - 1) %
                              pictureArray.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            tab1: (tab1 + 1) % pictureArray.length,
                          })
                        }
                      />
                    )}
                    <div className="item-portfolio-static">
                      <div
                        onClick={() =>
                          this.setState({ isOpen: true, tab1: index })
                        }
                      >
                        <div className="portfolio-static">
                          <div className="thumbnail-inner">
                            <div className="thumbnail">
                              <a href="#portfolio-details">
                                <img
                                  style={{
                                    height: "250px",
                                    width: "250px",
                                    objectFit: "cover",
                                  }}
                                  src={value}
                                  alt="Portfolio Images"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="content">
                            <div className="inner">
                              <p>{value.cat_title}</p>
                              <h4>
                                <a href="#portfolio-details">{value.title}</a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}

        <div className="portfolio-related-work pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="theme-color font--18 fontWeight600">
                    {related_work}
                  </span>
                  <h2>{our_more_project}</h2>
                </div>
              </div>
            </div>
            <div className="row mt--10">
              {productSliced.map((product, index) => (
                <div className="col-lg-4 col-md-3 col-sm-6 col-12" key={index}>
                  <div className="portfolio text-left mt--40">
                    <div className="thumbnail-inner">
                      <div
                        className={`thumbnail`}
                        style={{ backgroundImage: `url(${product.picture})` }}
                      ></div>
                      <div
                        className="bg-blr-image"
                        style={{ backgroundImage: `url(${product.picture})` }}
                      ></div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        {/* <p>{product.category}</p> */}
                        <h4>
                          <a
                            href={`/product-details/${product.id}/${product.cat_id}/${lang_url}`}
                          >
                            {product.title}
                          </a>
                        </h4>
                        <div className="portfolio-button">
                          <a
                            className="rn-btn"
                            href={`/product-details/${product.id}/${product.cat_id}/${lang_url}`}
                          >
                            {view}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="view-more-btn mt--60 text-center">
                  <a
                    className="rn-button-style--2 btn-solid"
                    href={`/job/${lang_url}`}
                  >
                    <span>{other_project}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <CallAction lang_url={lang_url} />
        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default ProductDetails;
