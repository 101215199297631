import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import NewsBread from "../elements/common/NewsBread";
// import Pagination from "../elements/common/Pagination";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import NewsList from "../elements/blog/BlogContent";

class Blog extends Component {
  render() {
    const lang_url = this.props.match.params.lang;
    var lang_title = "Blog";
    if (lang_url === "mn") {
      lang_title = "Нийтлэл";
    } else {
      lang_title = "Blog";
    }
    return (
      <React.Fragment>
        <PageHelmet pageTitle={lang_title} />
        <Header
          lang_url={lang_url}
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <NewsBread title={lang_title} />
        {/* End Breadcrump Area */}

        {/* Start Blog Area */}
        <div className="rn-blog-area ptb--120 bg_color--1">
          <div className="container">
            <NewsList lang_url={lang_url} />
            <div className="row mt--20">
              <div className="col-lg-12">
                {/* Start Pagination Area */}
                {/* <Pagination /> */}
                {/* End Pagination Area */}
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <CallAction lang_url={lang_url} />
        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default Blog;
