import React from "react";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "mn"],
    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets//locales/{{lng}}/translation.json",
    },
    react: { useSuspense: false },
  });

const CallAction = (props) => {
    const { t } = useTranslation();
    const lang_url = props.lang_url;
  return (
    <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="inner text-center">
              <span>{t("ready")}</span>
              <h2>{t("work_together")}</h2>
              <a className="rn-button-style--2" href={`/contact/${lang_url}`}>
                <span>{t("contact")}</span>
              </a>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </div>
  );
};
export default CallAction;
