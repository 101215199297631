import React from "react";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import Business from "./Business";
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "mn"],
    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets//locales/{{lng}}/translation.json",
    },
    react: { useSuspense: false },
  });

export default function AppLanguage(props) {
  // const lang_url = this.match.params.lang;
  const lang_url = props.lang_url;
  const { t } = useTranslation();
  return (
    <div>
      <Business blah={t} lang_url={lang_url}/>
    </div>
  );
}
