import React, { useEffect, useState } from "react";
import BlogList from "./BlogList";
import axios from "axios";

export default function NewsList(props) {
  /*eslint-disable */
  const lang_url = props.lang_url;
  const [news, getNews] = useState("");
  useEffect(() => {
    getAllNews();
  }, []);  
  const getAllNews = () => {
    axios
      .get(
        "http://contentlab.mn/teq111/json/news.php?lang=" + lang_url
      )
      .then((response) => {
        const allNews = response.data;
        getNews(allNews);
      });
  };
  /*eslint-enable */
  return (
    <div className="row">
      <BlogList news={news} lang_url={lang_url} />
    </div>
  );
}
