import React from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "mn"],
    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      cache: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    react: { useSuspense: false },
  });

function Header(props) {
  var elements = document.querySelectorAll(".has-droupdown > a");
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  // this.menuTrigger = this.menuTrigger.bind(this);
  // this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
  // //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
  // window.addEventListener("load", function () {
  //   console.log("All assets are loaded");
  // });

  function menuTrigger() {
    document.querySelector('.header-wrapper').classList.toggle('menu-open')
  }

 function CLoseMenuTrigger() {
  document.querySelector('.header-wrapper').classList.toggle('menu-open')
}
  const btnStyle = { border: "none", padding: 0 };
  const { logo, color = "default-color" } = props;
  const lang_url = props.lang_url;
  let logoUrl;
  if (logo === "light") {
    logoUrl = <img src="/assets/images/logo2.png" alt="Digital Agency" />;
  } else if (logo === "dark") {
    logoUrl = <img src="/assets/images/logo.1png" alt="Digital Agency" />;
  } else if (logo === "symbol-dark") {
    logoUrl = <img src="/assets/images/logo2.png" alt="Digital Agency" />;
  } else if (logo === "symbol-light") {
    logoUrl = <img src="/assets/images/logo2.png" alt="Digital Agency" />;
  } else {
    logoUrl = (
      <img width="200" src="/assets/images/logo2.png" alt="Digital Agency" />
    );
  }
  const { t } = useTranslation();
  return (
    // <Suspense fallback={{ loadingMarkup }}>
    <header
      className={`header-area formobile-menu header--transparent ${color}`}
    >
      <div className="header-wrapper" id="header-wrapper">
        <div className="header-left">
          <div className="logo">
            <a href={`/${lang_url}`}>{logoUrl}</a>
          </div>
        </div>
        <div className="header-right">
          <nav className="mainmenunav d-lg-block">
            <ul className="mainmenu">
              <li>
                <Link style={{fontSize: "14px"}} to={`/${lang_url}`} id="home">
                  <strong>{t("home")}</strong>
                </Link>
              </li>
              <li className="has-droupdown">
                <Link style={{fontSize: "14px"}} to={`/about/${lang_url}`}>
                  <strong>{t("about")}</strong>
                </Link>
                <ul className="submenu">
                  <li>
                    <Link style={{fontSize: "14px"}} to={`/about/${lang_url}`}>
                      <strong>{t("about")}</strong>
                    </Link>
                  </li>
                  <li>
                    <Link style={{fontSize: "14px"}} to={`/team/${lang_url}`}>
                      <strong>{t("team")}</strong>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="has-droupdown">
                <Link style={{fontSize: "14px"}} to={`/job/${lang_url}`}>
                  <strong>{t("doing")}</strong>
                </Link>
                <ul className="submenu">
                  <li>
                    <Link style={{fontSize: "14px"}} to={`/service/${lang_url}`}>
                      <strong>{t("service")}</strong>
                    </Link>
                  </li>
                  <li>
                    <Link style={{fontSize: "14px"}} to={`/job/${lang_url}`}>
                      <strong>{t("job")}</strong>
                    </Link>
                  </li>
                  {/* <li>
                      <Link to="/service">Service</Link>
                    </li> */}
                </ul>
              </li>
              {/* <li>
                <Link style={{fontSize: "14px"}} to={`/blog/${lang_url}`}>
                  <strong>{t("post")}</strong>
                </Link>
              </li> */}
              <li>
                <Link style={{fontSize: "14px"}} to={`/contact/${lang_url}`}>
                  <strong>{t("contact")}</strong>
                </Link>
              </li>
              <li>
                <a href="en">
                  <button
                    id="flag"
                    style={btnStyle}
                    onClick={() => i18next.changeLanguage("en")}
                  >
                    <img
                      id="imgFlag"
                      src="/assets/images/icons/usa_icon.png"
                      width={25}
                      height={25}
                      style={{ marginTop: "0px", marginRight: "10px" }}
                      alt="flag"
                    />
                  </button>
                </a>
                <a href="mn">
                  <button
                    id="flag"
                    style={btnStyle}
                    onClick={() => i18next.changeLanguage("mn")}
                  >
                    <img
                      id="imgFlag"
                      src="/assets/images/icons/mn_icon.png"
                      width={25}
                      height={25}
                      style={{ marginTop: "0px" }}
                      alt="flag"
                    />
                  </button>
                </a>
              </li>
            </ul>
          </nav>
          <div className="header-btn">
              <a
                className="rn-btn"
                href="https://themeforest.net/checkout/from_item/25457315?license=regular"
              >
                {/* <span>buy now</span> */}
              </a>
            </div>

          <div className="humberger-menu d-block d-lg-none pl--20">
            <span
              onClick={menuTrigger}
              className="menutrigger text-white"
            >
              <FiMenu />
            </span>
          </div>
          <div className="close-menu d-block d-lg-none">
            <span
            onClick={CLoseMenuTrigger}
            className="closeTrigger">
              <FiX />
            </span>
          </div>
        </div>
      </div>
    </header>
    // </Suspense>
  );
}
export default Header;
