import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from "react-modal-video";
// import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
// import BlogList from "../elements/blog/BlogList";
import parse from "html-react-parser";

class BlogDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      newsDetail: [],
      newsRelated: [],
    };

    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    const news_id = this.props.match.params.id;
    //Дэлгэрэнгүй мэдээ хэсгийн API
    fetch(
      "http://contentlab.mn/teq111/json/news_detail.php?news_id=" +
        news_id
    )
      .then((response) => response.json())
      .then((data) => this.setState({ newsDetail: data }));
    fetch(
      "http://contentlab.mn/teq111/json/news_related.php?id=" +
        news_id
    )
      .then((response) => response.json())
      .then((data) => this.setState({ newsRelated: data }));
  }

  render() {
    const lang_url = this.props.match.params.lang;
    const newsDetail = this.state.newsDetail;
    const newsRelated = this.state.newsRelated;
    const description = newsDetail.description;
    const stringDesc = JSON.stringify(description);
    const subDesc = stringDesc
      ? stringDesc.substring(1, stringDesc.length - 1)
      : " ";
    const parseDesc = parse(subDesc);
    var view = "View more";
    var related_post = "Related works";
    var other_post = "Other products";
    if (lang_url === "mn") {
      related_post = "ТӨСТЭЙ МЭДЭЭ";
      view = "Дэлгэрэнгүй";
      other_post = "Бусад мэдээ";
    } else {
      related_post = "RELATED NEWS";
      view = "View more";
      other_post = "Other news";
    }

    return (
      <React.Fragment>
        <PageHelmet pageTitle="Мэдээ" />
        <Header
          lang_url={lang_url}
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title theme-gradient">{newsDetail.title}</h2>
                  {/* <ul className="blog-meta d-flex justify-content-center align-items-center">
                        <li>
                          <FiClock />
                          May 18, 2020
                        </li>
                        <li>
                          <FiUser />
                          NipaBali
                        </li>
                        <li>
                          <FiMessageCircle />
                          15 Comments
                        </li>
                        <li>
                          <FiHeart />
                          Like
                        </li>
                      </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <div className="thumbnail">
                      <img src={`${newsDetail.picture}`} alt="Blog Images" />
                    </div>
                    <p className="mt--40">{parseDesc}</p>
                    {/* <blockquote className="rn-blog-quote">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes.
                    </blockquote>
                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                      <div className="thumbnail">
                        <img
                          className="w-100"
                          src="/assets/images/blog/blog-single-01.png"
                          alt="BLog Images"
                        />
                        <span>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do
                        </span>
                      </div>
                      <div className="content">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Risus commodo .
                        </p>
                        <h4 className="title">Ordered & Unordered Lists.</h4>
                        <ul className="list-style">
                          <li>Yet this above sewed flirted opened ouch</li>
                          <li>Goldfinch realistic sporadic ingenuous</li>
                          <li>
                            Abominable this abidin far successfully then like
                            piquan
                          </li>
                          <li>Risus commodo viverra</li>
                          <li>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </li>
                        </ul>
                        <h4 className="title">Ordered & Unordered Lists.</h4>
                        <ul className="list-style">
                          <li>Yet this above sewed flirted opened ouch</li>
                          <li>Goldfinch realistic sporadic ingenuous</li>
                          <li>
                            Abominable this abidin far successfully then like
                            piquan
                          </li>
                          <li>Risus commodo viverra</li>
                        </ul>
                      </div>
                    </div>

                    <p className="mt--25 mt_sm--5">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour, or randomised words which
                      don't look even slightly believable. If you are going to
                      use a passage of Lorem Ipsum. You need to be sure there
                      isn't anything embarrassing hidden in the middle of text.
                      All the Lorem Ipsum generators on the Internet tend
                      toitrrepeat predefined chunks. Necessary, making this the
                      first true generator on the Internet. It re are many
                      variations of passages of Lorem Ipsum available, but the
                      majority have suffered alteration in some form, by
                      injectedeed eedhumour, or randomised words which don't
                      look even slightly believable.
                    </p>
                    <div className="video-wrapper position-relative mb--40">
                      <div className="thumbnail">
                        <img
                          src="/assets/images/blog/bl-big-01.jpg"
                          alt="Blog Images"
                        />
                      </div>
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="ZOoVOfieAF8"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button
                        className="video-popup position-top-center"
                        onClick={this.openModal}
                      >
                        <span className="play-icon"></span>
                      </button>
                    </div>
                    <p className="mb--0">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour, or randomised words which
                      don't look even slightly believable. If you are going to
                      use a passage of Lorem Ipsum. You need to be sure there
                      isn't anything embarrassing hidden in the middle of text.
                      All the Lorem Ipsum generators on the Internet tend
                      toitrrepeat predefined chunks. Necessary, making this the
                      first true generator on the Internet. It re are many
                      variations of passages of Lorem Ipsum available, but the
                      majority have suffered alteration in some form, by
                      injectedeed eedhumour, or randomised words which don't
                      look even slightly believable.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="portfolio-related-work pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="theme-color font--18 fontWeight600">
                    {related_post}
                  </span>
                  {/* <h2>{our_more_post}</h2> */}
                </div>
              </div>
            </div>
            <div className="row mt--10">
              {newsRelated.map((news, index) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <Link to={`/blog-details/${news.id}/${lang_url}`}>
                        <img
                          className="w-100"
                          src={`${news.picture}`}
                          alt="Blog Images"
                        />
                      </Link>
                    </div>
                    <div className="content">
                      {/* <p className="blogtype">{news.title}</p> */}
                      <h5 style={{ color: "white" }}>
                        {" "}
                        <a
                          style={{ color: "white" }}
                          href={`/blog-details/${news.id}/${lang_url}`}
                        >
                          {news.title}
                        </a>
                      </h5>
                      <div className="blog-btn">
                        <a
                          className="rn-btn text-white"
                          style={{ color: "white" }}
                          href={`/blog-details/${news.id}/${lang_url}`}
                        >
                          {view}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="view-more-btn mt--60 text-center">
                  <a
                    className="rn-button-style--2 btn-solid"
                    href={`/blog/${lang_url}`}
                  >
                    <span>{other_post}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start BLog Comment Form  */}
        {/* <div className="blog-comment-form pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h3 className="title mb--40 fontWeight500">Leave a Reply</h3>
                  <form action="#">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="rnform-group">
                          <input type="text" placeholder="Name" />
                        </div>
                        <div className="rnform-group">
                          <input type="email" placeholder="Email" />
                        </div>
                        <div className="rnform-group">
                          <input type="text" placeholder="Website" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="rnform-group">
                          <textarea
                            type="text"
                            placeholder="Comment"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="blog-btn">
                          <a className="rn-button-style--2 btn-solid" href="#"></a>
                          <Link
                            className="rn-button-style--2 btn-solid"
                            to="/blog-details"
                          >
                            <span>SEND MESSAGE</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End BLog Comment Form  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <CallAction lang_url={lang_url} />

        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default BlogDetails;
