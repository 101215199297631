import React, { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import Breadcrumb from "../elements/common/Breadcrumb";
import FooterTwo from "../component/footer/FooterTwo";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Helmet from "../component/common/Helmet";
import CallAction from "../elements/callaction/CallAction";

class CreativePortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: [],
      category: [],
    };
  }
  componentDidMount() {
    const lang_url = this.props.match.params.lang;
    const cat_id = this.props.match.params.id;

    //Category хэсгийн API
    fetch(
      "http://contentlab.mn/teq111/json/product.php?cat_id=" +
        cat_id +
        "lang=" +
        lang_url
    )
      .then((response) => response.json())
      .then((data) => this.setState({ product: data }));

    fetch(
      "http://contentlab.mn/teq111/json/category.php?lang=" + lang_url
    )
      .then((response) => response.json())
      .then((data) => this.setState({ category: data }));
  }

  render() {
    const lang_url = this.props.match.params.lang;
    var view = "View more";
    var all = "All";
    var bread_title = "Projects"
    if (lang_url === "mn") {
      view = "Дэлгэрэнгүй";
      all = "Бүгд";
      bread_title = "Бүтээлүүд";
    } else {
      view = "View more";
      all = "All";
      bread_title = "Projects";
    }
    const product = this.state.product;
    const category = this.state.category;
    return (
      <div className="creative-portfolio">
        <Helmet pageTitle="Бүтээлүүд" />

        {/* Start Header Area  */}
        <Header
          lang_url={lang_url}
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        <Breadcrumb title={bread_title} />
        {/* End Header Area  */}

        {/* Start Portfolio Area  */}
        <div className="row" style={{ marginBottom: "10px" }}>
          <div className="col-lg-12">
            <div className="view-more-btn mt--60 text-center">
              <a
                className="home_category_button btn-solid"
                style={{ margin: "10px" }}
                href={`/job/${lang_url}`}
              >
                <span>{all}</span>
              </a>
              {category.map((value, i) => (
                <a
                  className="home_category_button btn-solid"
                  style={{ margin: "10px" }}
                  href={`/job/${value.id}/${lang_url}`}
                >
                  <span>{value.title}</span>
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="creative-portfolio-wrapper bg_color--1">
          <div className="creative-portfolio-wrapper plr--10">
            <div className="row">
              {product.map((product, index) => (
                <div className="col-lg-3 col-md-3 col-sm-6 col-12" key={index}>
                  <div className="portfolio text-left mt--40">
                    <div className="thumbnail-inner">
                      <div
                        className={`thumbnail`}
                        style={{ backgroundImage: `url(${product.picture})` }}
                      ></div>
                      <div
                        className="bg-blr-image"
                        style={{ backgroundImage: `url(${product.picture})` }}
                      ></div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        {/* <p>{product.category}</p> */}
                        <h4>
                          <Link
                            to={`/product-details/${product.id}/${product.cat_id}/${lang_url}`}
                          >
                            {product.title}
                          </Link>
                        </h4>
                        <div className="portfolio-button">
                          <Link
                            className="rn-btn"
                            to={`/product-details/${product.id}/${product.cat_id}/${lang_url}`}
                          >
                            {view}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <br />
        <br />
        {/* End Portfolio Area  */}
        <CallAction lang_url={lang_url} />
        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </div>
    );
  }
}

export default CreativePortfolio;
