import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import parse from "html-react-parser";

class ServiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      serviceDetail: [],
      banner: [],
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  componentDidMount() {
    const service_id = this.props.match.params.id;
    //Үйлчилгээ дэлгэрэнгүй хэсгийн API
    fetch(
      "http://contentlab.mn/teq111/json/service_detail.php?id="
        + service_id
    )
      .then((response) => response.json())
      .then((data) => this.setState({ serviceDetail: data }));
    fetch("http://contentlab.mn/teq111/json/contact.php")
      .then((response) => response.json())
      .then((data) => this.setState({ banner: data }));
  }
  render() {
    const lang_url = this.props.match.params.lang;
    const serviceDetail = this.state.serviceDetail;
    const banner = this.state.banner;
    const description = serviceDetail.desc;
    const stringDesc = JSON.stringify(description);
    const subDesc = stringDesc
      ? stringDesc.substring(1, stringDesc.length - 1)
      : " ";
    const parseDesc = parse(subDesc);

    var service = "Service";
    if (lang_url === "en") {
      service = "Service";
    } else {
      service = "Үйлчилгээ";
    }
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle={service} />
        {/* End Pagehelmet  */}

        <Header
          lang_url={lang_url}
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="3"
          style={{ backgroundImage: `url(${banner.service_banner})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    {serviceDetail.title}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={serviceDetail.picture}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          {parseDesc !== "" ? parseDesc : null}
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <CallAction lang_url={lang_url} />
        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
