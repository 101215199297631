import React, { Component } from "react";
import AppLanguage from "./languageTest";
class thirdLanguage extends Component {
  constructor(props) {
    super(props);
    console.log();
  }
  render() {
    const lang_url = this.props.match.params.lang
    return (
      <div>
        <AppLanguage lang_url = {lang_url} />
      </div>
    );
  }
}
export default thirdLanguage;
