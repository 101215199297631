import React, { Component } from "react";
// import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";
import { parse } from "semver";

class ServiceThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: [],
    };
  }
  componentDidMount() {
    fetch("http://contentlab.mn/teq111/json/service.php")
      .then((response) => response.json())
      .then((data) => this.setState({ service: data }));
  }
  render() {
    const service = this.state.service;
    return (
      <React.Fragment>
        <div className="row">
          {service.map((val, i) => (
            <div
              className="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
              key={i}
            >
              {/* <a href="/service-details"> */}
              <div className="service service__style--2">
                <div className="icon">{val.icon}</div>
                <div className="content">
                  <h3 className="title">{val.title}</h3>
                  <p>
                    {parse(service.desc.substring(1, service.desc.length - 1))}
                  </p>
                </div>
              </div>
              {/* </a> */}
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
