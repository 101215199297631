import React, { Component } from "react";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
class BrandTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: [],
    };
  }
  componentDidMount() {
    fetch("http://contentlab.mn/teq111/json/partner.php")
      .then((response) => response.json())
      .then((data) => this.setState({ banner: data }));
  }
  render() {
    const banner = this.state.banner;
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          {banner.map((banner, index) => (
            <div key ={index}>
              <li>
              <a href={`${banner.title}`}>
                <img src={`${banner.picture}`} alt="Logo Images" />
              </a>
            </li>
            </div>
          ))}
        </ul>
        {/* <div className="portfolio-area pt--90 pb--140 bg_color--1 "> */}
          <div className="rn-slick-dot brand-style-3">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="slick-space-gutter--15">
                    <Slider {...slickDot}>
                      {banner.map((value, index) => (
                        <div key={index}>
                          <div className="thumbnail" style={{ marginTop: "30px" }}>
                            <img src={`${value.picture}`} alt="logo" />
                          </div>
                          <div className="content">
                            <h4 className="title">{value.name}</h4>
                            <p className="designation">{value.title}</p>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* </div> */}
      </React.Fragment>
    );
  }
}
export default BrandTwo;
