import React from "react";
import { Link } from "react-router-dom";
export default function BlogList(props) {
  const displayNews = (props) => {
    const lang_url = props.lang_url;
    const { news } = props;
    var view = "View more";
    if (lang_url === "mn") {
      view = "Дэлгэрэнгүй";
    } else {
      view = "View more";
    }
    if (news.length > 0) {
      return news.map((news, index) => {
        return (
          <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
            <div className="blog blog-style--1">
              <div className="thumbnail">
                <Link to={`/blog-details/${news.id}/${lang_url}`}>
                  <img
                    className="w-100"
                    src={`${news.picture}`}
                    alt="Blog Images"
                  />
                </Link>
              </div>
              <div className="content">
                <p className="blogtype">{news.title}</p>
                <h4 className="title">
                  <Link to={`/blog-details/${news.id}`}>{news.title}</Link>
                </h4>
                <div className="blog-btn">
                  <Link
                    className="rn-btn text-white"
                    to={`/blog-details/${news.id}/${lang_url}`}
                  >
                    {view}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <h3> no news yet </h3>;
    }
  };
  return <>{displayNews(props)}</>;
}
